.label {
	width: 100%;
	font-weight: 800;
	margin: 10px 0;
}

.field {
	width: 100%;

	input {
		width: 100%;
		border: 1px white solid;
		background: rgba($color: #ffffff, $alpha: 0.2);
		border-radius: 8px;
		height: 38px;
		color: white;
		padding: 0 10px;
		outline: none;
		box-sizing: border-box;
	}
	::placeholder {
		color: white;
	}
}

.error {
	color: white;
	margin: 5px;
}
