.submit {
	background-color: #6a1b9a;
	color: white;
	padding: 8px;
	margin: 20px 0;
	width: 100%;
	border: none;
	border-radius: 8px;
	cursor: pointer;
}

.submitError {
	@extend .submit;
	cursor: not-allowed;
}
